var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "commentSubmissionAdmin" },
        [
          _c("span", { staticClass: "label" }, [_vm._v("Comment")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.commentUnderReview.text,
                expression: "commentUnderReview.text",
              },
            ],
            staticClass: "textBox",
            attrs: { type: "text" },
            domProps: { value: _vm.commentUnderReview.text },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.commentUnderReview, "text", $event.target.value)
              },
            },
          }),
          _c("label", { staticClass: "status" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Status")]),
            _c("div", { staticClass: "control" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.commentUnderReview.status,
                      expression: "commentUnderReview.status",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.commentUnderReview,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.statuses, function (status) {
                  return _c(
                    "option",
                    { key: status.value, domProps: { value: status.value } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(status.text) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("br"),
          _c("ButtonSubmit", {
            attrs: { copy: "Save" },
            on: { callback: _vm.submit },
          }),
          _c("ButtonCancel", {
            staticClass: "cancel",
            on: { click: _vm.closeModal },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
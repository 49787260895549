var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "admin" }, [
    _c("div", { staticClass: "menu" }, [
      _c(
        "div",
        {
          staticClass: "menuItem",
          class: { selected: _vm.brandStoryAdmin },
          on: { click: _vm.showBrandStoryAdmin },
        },
        [_vm._v("\n      Brand Story Submissions\n    ")]
      ),
      _c(
        "div",
        {
          staticClass: "menuItem",
          class: { selected: _vm.commentsAdmin },
          on: { click: _vm.showCommentsAdmin },
        },
        [_vm._v("\n      Brand Story Comments\n    ")]
      ),
      _c(
        "div",
        {
          staticClass: "menuItem",
          class: { selected: _vm.whpcStoryAdmin },
          on: { click: _vm.showWhpcStoryAdmin },
        },
        [_vm._v("\n      Whpc Story Submissions\n    ")]
      ),
      _c(
        "div",
        {
          staticClass: "menuItem",
          class: { selected: _vm.profileImagesAdmin },
          on: { click: _vm.showProfileImagesAdmin },
        },
        [_vm._v("\n      Profile Images\n    ")]
      ),
      _c(
        "div",
        { staticClass: "button" },
        [
          _c("ButtonSubmit", {
            attrs: { copy: "Download User Report", allowSizing: true },
            on: { callback: _vm.downloadUserReport },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("BrandStoryAdmin", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.brandStoryAdmin,
              expression: "brandStoryAdmin",
            },
          ],
        }),
        _c("CommentAdmin", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.commentsAdmin,
              expression: "commentsAdmin",
            },
          ],
        }),
        _c("WhpcStoryAdmin", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.whpcStoryAdmin,
              expression: "whpcStoryAdmin",
            },
          ],
        }),
        _c("UserProfileImageAdmin", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.profileImagesAdmin,
              expression: "profileImagesAdmin",
            },
          ],
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "whpcSubmission" },
    [
      _c("LincolnEffortlessExperienceLogoDark"),
      _c("h2", [_vm._v("Add Story")]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("TextBox", {
            staticClass: "textBox",
            attrs: { label: "Subject", name: "subject", required: true },
            model: {
              value: _vm.whpcStory.subject,
              callback: function ($$v) {
                _vm.$set(_vm.whpcStory, "subject", $$v)
              },
              expression: "whpcStory.subject",
            },
          }),
          _c(
            "div",
            { staticClass: "storyBody" },
            [
              _c("RichTextBox", {
                attrs: { initialContent: _vm.whpcStory.body },
                on: { update: _vm.updateBody },
              }),
            ],
            1
          ),
          _c("label", { staticClass: "status" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Status")]),
            _c("div", { staticClass: "control" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.whpcStory.status,
                      expression: "whpcStory.status",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.whpcStory,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.statuses, function (status) {
                  return _c(
                    "option",
                    { key: status.value, domProps: { value: status.value } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(status.text) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("ImageCropperForStory", {
            staticClass: "imagePreviewer",
            attrs: { storyData: _vm.whpcStory },
            on: { updatedImage: _vm.updatedImage },
          }),
          _c("ButtonSubmit", {
            attrs: { copy: "Save" },
            on: { callback: _vm.submit },
          }),
          _c("ButtonCancel", {
            staticClass: "cancel",
            on: { click: _vm.closeModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brandStorySubmission" },
    [
      _c("LincolnEffortlessExperienceLogoDark"),
      _c("h2", [_vm._v("Brand Story Review")]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Date Submitted",
              name: "submittedOn",
              disabled: true,
            },
            model: {
              value: _vm.submittedOn,
              callback: function ($$v) {
                _vm.submittedOn = $$v
              },
              expression: "submittedOn",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Submitted By",
              name: "submittedBy",
              disabled: true,
            },
            model: {
              value: _vm.fullName,
              callback: function ($$v) {
                _vm.fullName = $$v
              },
              expression: "fullName",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: { label: "Subject", name: "subject", required: true },
            model: {
              value: _vm.storyUnderReview.subject,
              callback: function ($$v) {
                _vm.$set(_vm.storyUnderReview, "subject", $$v)
              },
              expression: "storyUnderReview.subject",
            },
          }),
          _c("label", { staticClass: "status" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Status")]),
            _c("div", { staticClass: "control" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.storyUnderReview.status,
                      expression: "storyUnderReview.status",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.storyUnderReview,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.statuses, function (status) {
                  return _c(
                    "option",
                    { key: status.value, domProps: { value: status.value } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(status.text) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "required" }, [
            _vm._v("Fields marked with * are required."),
          ]),
          _vm._l(this.businessAreas, function (area) {
            return _c("BusinessAreaOption", {
              key: area.id,
              attrs: {
                label: area.name,
                name: _vm.businessAreasName,
                value: area.id,
                checked: _vm.isAreaSelected(area.id),
              },
              on: { updateSelectedAreas: _vm.updateSelectedAreas },
            })
          }),
          _c("div", { staticClass: "required" }, [
            _vm._v("Please select one."),
          ]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "tenetContainer" },
            _vm._l(this.tenets, function (tenet) {
              return _c("EffortlessTenetOption", {
                key: tenet.id,
                attrs: {
                  label: tenet.name,
                  name: _vm.effortlessTenetsName,
                  value: tenet.id,
                  checked: _vm.isTenetSelected(tenet.id),
                },
                on: { updateSelectedTenets: _vm.updateSelectedTenets },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "brandStoryBody" },
            [
              _c("RichTextBox", {
                attrs: { initialContent: _vm.storyUnderReview.body },
                on: { update: _vm.updateBody },
              }),
            ],
            1
          ),
          _c("ImageCropperForStory", {
            attrs: { storyData: _vm.story, isBrandStory: true },
            on: { updatedImage: _vm.updatedImage },
          }),
          this.existedAttachments.length > 0
            ? _c(
                "div",
                { staticClass: "existedAttachments" },
                _vm._l(this.existedAttachments, function (file, key) {
                  return _c("div", { key: key, staticClass: "file-listing" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.processFile(file)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.cleanFileName(file)) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "remove-file",
                        on: {
                          click: function ($event) {
                            return _vm.removeExistedAttachment(file.id)
                          },
                        },
                      },
                      [_vm._v("Remove")]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          _c("MultipleFiles", {
            attrs: { name: _vm.newAttachments },
            model: {
              value: _vm.newAttachments,
              callback: function ($$v) {
                _vm.newAttachments = $$v
              },
              expression: "newAttachments",
            },
          }),
          _c("ButtonSubmit", {
            attrs: { copy: "Send Now" },
            on: { callback: _vm.submit },
          }),
          _c("ButtonCancel", {
            staticClass: "cancel",
            on: { click: _vm.closeModal },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tenet" }, [
      _vm._v("\n      Effortless Tenet\n      "),
      _c("div", { staticClass: "arrowDown" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }